@font-face {
  font-family: "GothamNarrow-Bold";
  src: url("../../assets/fonts/GothamNarrow-Bold.eot");
  src: url("../../assets/fonts/GothamNarrow-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/GothamNarrow-Bold.woff") format("woff"),
    url("../../assets/fonts/GothamNarrow-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "GothamNarrow-Book";
  src: url("../../assets/fonts/GothamNarrow-Book.eot");
  src: url("../../assets/fonts/GothamNarrow-Book.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/GothamNarrow-Book.woff") format("woff"),
    url("../../assets/fonts/GothamNarrow-Book.ttf") format("truetype");
  font-weight: unset;
  font-style: normal;
}

@font-face {
  font-family: "GothamNarrow-Light";
  src: url("../../assets/fonts/GothamNarrow-Light.eot");
  src: url("../../assets/fonts/GothamNarrow-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/GothamNarrow-Light.woff") format("woff"),
    url("../../assets/fonts/GothamNarrow-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GothamNarrow-Medium";
  src: url("../../assets/fonts/GothamNarrow-Medium.eot");
  src: url("../../assets/fonts/GothamNarrow-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../assets/fonts/GothamNarrow-Medium.woff") format("woff"),
    url("../../assets/fonts/GothamNarrow-Medium.ttf") format("truetype");
  font-weight: unset;
  font-style: normal;
}
