@import "../base/breakpoints.scss";
@import "../base/colors.scss";
@import "../base/mixins.scss";

@include keyframes(opacity) {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
  background-color: $light-grey;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  position: relative;
  overflow-x: hidden;
  background-color: $blue;
  @include xxl {
    margin: 0 auto;
  }
}

.noScrollable {
  overflow: hidden;
}

.intro {
  position: fixed;
  z-index: -20;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: #000;
  height: calc(100vh + 400px);
  overflow: hidden;
  width: 100%;
  transition: 0.4s all ease-in-out;
  margin-top: -200px;
  display: none;
  iframe {
    width: 100%;
    height: 100%;
  }
}

.intro.visible {
  z-index: 20;
  opacity: 1;
  @include animation(7.5s, 0.5s, opacity, ease);
  transition: 0.4s all ease-in-out;
  display: block;
}

.back-to-top {
  position: fixed;
  bottom: 5%;
  right: 3%;
  width: 4rem;
  height: 4rem;
}
