h1,
h2,
h3,
h4,
p,
ul,
ol,
blockquote {
  margin: 0;
  font-weight: normal;
}

a {
  background-color: transparent;
  text-decoration: none;
}

b,
strong {
  font-weight: bolder;
}

img {
  width: 100%;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

cite {
  font-style: normal;
}

button {
  padding: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

ol,
ul {
  padding: 0;
}
