$white: #fff;
$white8: rgba(255, 255, 255, 0.8);
$white9: rgba(255, 255, 255, 1);

$blue: rgba(19, 23, 94, 1);

$gold: rgba(222, 197, 88, 1);

$dark-grey: #a9a9a9;
$light-grey: #eee;

$black: #000;
$black4: rgba(000, 000, 000, 0.4);

$red: rgba(224, 40, 28, 1);
